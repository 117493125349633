export default {
    submit: 'Einreichen',
	amount: 'Betrag',
    quantity: 'Quantity',
	price: 'Price',
	available: 'Verfügbar',
	total: 'Gesamt',
	total_balance: 'Gesamtvermögen',
	no_more: 'No More',
	optional: 'Optional',
    send: 'Senden',
    sending: 'Schicken',
    email: 'Email',
    eac: 'Email Authentifizierungscode',
	remark: 'Remark',
	tips: 'Tipps',
	copied: 'Kopiert',
	petf: 'Bitte füllen Sie dieses Feld aus',
	MAX: 'Max',
    order_no: 'Best.-Nr.',
    date_time: 'Terminzeit',
    status: 'Status',
    action: 'Aktion',
    details: 'Details',
    back: 'Back',
    refresh: 'Refresh',
    login_first: 'Anmelden First',
    coin: 'Coin',
    profit: 'Profit',
    name: 'Name',
    all: 'All',
    long: 'Bullisch',
	short: 'Bärisch',
    navbar: {
        home: 'Titelseite',
        subscribe: 'Abonnieren',
        fund: 'Funds',
        trade: 'Handel',
        help: 'Helfen',
        language: 'Sprache',
        sign_in: 'Anmelden',
        account: 'Meins',
        my_assets: 'My Wallet',
        wallet: 'Wallet',
        profile: 'Profile',
        user_center: 'User Center',
        settings: 'Settings',
        logout: 'Logout'
    },
    footer: {
        about_us: 'Über uns',
        help_center: 'Hilfezentrum',
        fees: 'Gebühren',
        user_agreement: 'Nutzungsbedingungen',
        contact_us: 'Kontaktiere uns',
        copyright: 'Urheberrechte'
    },
    home: {
        title1: 'EGC Exchange',
        title2: 'Ein globaler Marktführer im<br/>Bereich Kryptowährungen seit 2019',
        opened: 'Opened',
        more: 'More',
        trade: 'Trade',
        pair: 'Pair',
        price: 'Price',
        change: 'Change',
        high: 'High',
        low: 'Low',
        finished: 'Vol.',
        deal: 'Deal',
        service_title: 'Beginnen Sie noch heute Ihre Kryptowährungsreise',
        service1: {
            title: 'Zuverlässiges System',
            des1: 'Gewährleistung eines sicheren, stabilen und effizienten Ablaufs der Transaktion',
            des2: 'Eliteteam der Toptalente der Welt'
        },
        service2: {
            title: 'Sicherheit des Fonds',
            des1: 'HTTPS-Datenverschlüsselung, Identitätsüberprüfung',
            des2: 'Architektur auf Finanzebene, mehrfacher Sicherheitsschutz'
        },
        service3: {
            title: 'Das beste Erlebnis',
            des1: 'Schnelle Auszahlungen, Mehrsprachig',
            des2: 'Leistungsstarke Handels-Engine, schnelles Handelserlebnis'
        },
        service4: {
            title: 'Professionelle Dienste',
            des1: 'Professionelle internationale Serviceteams',
            des2: 'Reagieren Sie zeitnah auf Benutzerprobleme'
        }
    },
    help: {
        title: 'Hilfezentrum',
        feedback: 'Rückmeldung'
    },
    news: {
        title: 'Nachrichtenzentrum'
    },
    subscribe: {
        title: 'Subscribe Crypto',
        title1: 'Subscribing New Crypto',
        title2: 'We are committed to helping blockchain projects raise funds,<br/>find supporters, and make fresh and interesting good ideas come',
        new_currency: 'New currency',
        my_participation: 'My participation',
        project_details: 'Project Details',
        subscription_ratio: 'Subscription Ratio',
        current_price: 'Current Price',
        issue_quota: 'Issue Quota',
        participating: 'Participating',
        quantity: 'Quantity',
        enter_quantity: 'Enter Quantity',
        lock_period: 'Lock Period',
        months: 'Month(s)',
        thaw_interval: 'Thaw interval',
        approved: 'Approved',
        hours: 'Hour(s)',
        available: 'Available',
        ms: 'My Subscribing',
        mp: 'My Positions',
        purchase_limit: 'Purchase Limit',
        total: 'Total',
        fee: 'Fee',
        get_involved_now: 'Get Involved Now',
        end_date: 'End Date',
        project_des: 'Project Description',
        wtb: 'Waiting to Subscribe',
		Subscring: 'Open Subscribe',
		wtm: 'Waiting to Listing',
		Subscribe: 'Subscribe',
		on_market: 'Listed on Market',
		has_ended: 'Project has Ended',
		osfm: 'Open Subscribe for Me ?',
		Yes: "Yes",
		No: 'No',
		msr: 'My Subscribed Records',
		issue_date: 'Issue Date',
		Issue_Price: 'Issue Price',
		Now_Price: 'Now Price',
		Cost_Value: 'Cost Value',
		sd: 'Subscribe Date',
		daily: 'Daily',
		to: 'to',
		st: 'Subscribe Time',
		std: 'Listing Date',
		white_paper: 'White Paper',
		get_involved: 'Get Involved',
		eq: 'Enter Quantity',
		not_open_subscribe: 'Your Account not open for Subscribe, to Help',
		history_title: 'Subscribe Records',
		Fail_Rollback: 'Fail Rollback',
		Subscribing: 'Subscribing',
		oq: 'Order Quantity',
		sq: 'Quota Quantity',
		postitons_title: 'Crypto Postitons',
		cost: 'Cost',
		value: 'Value',
		atu: 'Apply to Unlock',
		locking_to: 'Locking to',
		finished: 'Finished',
		cost_or_price: 'Cost / Current Price',
		cost_or_value: 'Cost / Current Value',
		Profit: 'Profit',
		item_title: 'Project Details'
    },
    login: {
        title: 'Anmelden',
        dont_have_an_account:'Sie haben noch kein Konto ?',
        to_register: 'Registrieren',
        email: 'Email',
        password: 'Passwort',
        captcha: 'Captcha',
        click_me_show: 'Click Me Show',
        sign_in_now: 'Anmelden',
        forget_password: 'Passwort vergessen ?'
    },
    register: {
        title: 'Registrieren',
        phone: 'Phone',
        re_password: 'Bestätige das Passwort',
        invitation_code: 'Invitation Code',
        send_to_email: 'Send to Email',
        already_have_an_account: 'Sie haben bereits ein Konto ?',
        sign_in: 'Anmelden'
    },
    forget: {
        title: 'Rufen Sie das Passwort ab',
        retrieve_now: 'Rufen'
    },
    account: {
        title: 'Meins',
        recharge: 'Kautionen',
        profile: 'Profile',
        my_assets: 'My Assets',
        withdraw: 'Abhebungen',
        transactions: 'Trade Orders',
        contract: 'Contract',
        options_orders: 'Options Orders',
        inviting_and_awards: 'Inviting & Awards',
        settings: 'Settings',
        list_of_assets: 'List of Assets',
        available: 'Available',
        frozen: 'Frozen',
        available_log: 'Available Log',
        event: 'Event',
        time: 'Time',
        email: 'Email',
        login_logs: 'Login Logs',
        change_password: 'Change Password',
        kyc_verify: 'KYC Verify',
        verify: 'Verify',
        verify_state: {
            unsubmitted: 'Unsubmitted',
            fail: 'Fail',
            checking: 'Checking',
            success: 'Success'
        }
    },
    wallet: {
        title: 'My Wallet',
        blockchain: 'Blockchain',
        hash: 'Hash',
        tx_hash: 'Tx Hash',
        sb: 'Select Blockchain',
        spot: 'Spot',
        total_value: 'Total Value',
        umb: 'USD(S)-M Margin Balance',
        positions: 'Positions',
        futures: 'Futures',
		tb: 'Total Balance',
		recharge: 'Kautionen',
		withdraw: 'Abhebungen',
		exchange: 'Swap',
		in_order: 'In Order',
		value: 'Value',
		pea: 'Please enter Amount',
		petp: 'Please enter Trade Password',
		log_type: {
			0: 'Kautionen',
			1: 'System add',
			2: 'System remove',
			6: 'Abhebungen',
			7: 'Abhebungen fail rollback',
			8: 'Swap out',
			9: 'Swap in',
			12: 'Frozen',
			13: 'Thaw',
			14: 'Transfer out',
			15: 'Transfer in',

			20: 'Coin subscribe',
			21: 'Coin unlock',
			22: 'Coin subscribe fail rollback',
			23: 'Coin unlock and sell',
			24: 'Coin unlock taxes',

			30: 'Order Buy Spot',
			31: 'Order Buy Spot',
			32: 'Cancel the Order for Spot Trade',
			33: 'Successful Trade Spot',

			50: 'Options Submit Order',
			51: 'Options Win',
			52: 'Options return Principal'
        }
	},
    recharge: {
		title: 'Kautionen',
		cc: 'Click Copy',
		uv: 'Upload Voucher',
		puv: 'Please Upload Voucher',
		nor: 'Not open Recharge',
		history: {
			title: 'Recharge Record',
			checking: 'Checking',
			success: 'Success',
			fail: 'Fail'
		}
	},
    withdraw: {
		title: 'Abhebungen',
        st: 'Submit Time',
		now: 'Not open Abhebungen',
        swa: 'Select Wallet Address',
        awa: 'Add Wallet Address',
        nwa: 'New Wallet Address',
        epwa: 'Enter Payee Wallet Address',
		history: {
			title: 'Abhebungen Record',
			checking: 'Checking',
			success: 'Success',
			fail: 'Fail',
			cancel: 'Cancel'
		}
	},
    exchange: {
		title: 'Coin Swap',
        from_amount: 'Export Amount',
        checkout_to: 'Checkout To',
		history: {
			title: 'Swap Record',
		}
	},
    transfer: {
		title: 'Transfer',
		history: {
			title: 'Transfer Record',
		}
	},
    share: {
		title: 'Share',
	},
    user: {
		email: 'Email',
		email_captcha: 'Email Authentication Code',
		captcha: 'Captcha',
		pwd: 'Password',
		confirm_pwd: 'Confirm Password',
		trade_pwd: 'Trade Password',
		confirm_trade_pwd: 'Confirm Trade Password',
		wa: 'Wallet Address',
		peea: 'Please enter Email Address',
		pewa: 'Please Enter Wallet Address',
		pec: 'Please enter Captcha',
		peec: 'Please enter Email Authentication Code',
		cfii: 'The Captcha only allows letters and numbers',
		ecfii: 'The Email authentication code only allows numbers',
		eafii: 'Email Address format is incorrect',
		pep: 'Please enter Password',
		pecp: 'Please enter Confirm Password',
		p_diff: 'The two passwords entered are inconsistent',
		pfii: 'The password only allows letters and numbers',
		uff: 'Upload File Fail'
	}
}