export default {
    submit: 'Soumettre',
	amount: 'Montant',
    quantity: 'Quantité',
	price: 'Prix',
	available: 'Disponible',
	total: 'Total',
	total_balance: 'Total Balance',
	no_more: 'No More',
	optional: 'Optional',
    send: 'Envoyer',
    sending: 'Envoi en cours',
    email: 'Email',
    eac: 'Email code d\'identification',
	remark: 'Remark',
	tips: 'conseils',
	copied: 'Copié',
	petf: 'Please enter this field',
	MAX: 'Max',
    order_no: 'Order No.',
    date_time: 'Date Time',
    status: 'Statut',
    action: 'Action',
    details: 'Details',
    back: 'Retour',
    refresh: 'Rafraîchir',
    login_first: 'Connexion First',
    coin: 'Coin',
    profit: 'Profit',
    name: 'Name',
    all: 'All',
    long: 'Long',
    short: 'Short',
    navbar: {
        home: 'Maison',
        subscribe: 'Abonnement',
        fund: 'Funds',
        trade: 'Commerce',
        help: 'Aide',
        language: 'Langue',
        sign_in: 'Connexion',
        account: 'Compte',
        my_assets: 'My Wallet',
        wallet: 'Wallet',
        profile: 'Profile',
        user_center: 'User Center',
        settings: 'Settings',
        logout: 'Logout'
    },
    footer: {
        about_us: 'À propos de nous',
        help_center: 'Centre d\'aide',
        fees: 'Frais',
        user_agreement: 'Accord de l\'utilisateur',
        contact_us: 'Contactez-nous',
        copyright: 'droits d\'auteur'
    },
    base: {
        back: 'Back',
        buy: 'Buy',
        sell: 'Sell',
        no_more: 'No more',
        loading: 'Loading',
        sending: 'Sending',
        pending: 'Pending',
        success: 'Success',
        fail: 'Fail',
        warning: 'Warning',
        danger: 'Danger',
        error: 'Error',
        email: 'Email',
        password: 'Password',
        old_pw: 'Old-Passwd',
        new_pw: 'New-Passwd',
        re_pw: 'Re-Passwd',
        pay_password: 'Trade Password',
        submit: 'Submit',
        update: 'Update',
        new: 'New',
        remark: 'Remark',
        voucher: 'Voucher',
        amount: 'Amount',
        enter_usdt_quantily: 'Enter USDT quantily',
        enter_trade_password: 'Enter trade password',
        login_first: 'Login first',
        state: 'State',
        profit: 'Profit',
        coin_profit: 'Coin Profit',
        refresh: 'Refresh',
        total: 'Total',
        deal: 'Deal'
    },
    home: {
        banner: [
            require('@/assets/index/banner1_en-us.png'),
            require('@/assets/index/banner2_en-us.png'),
            require('@/assets/index/top-bg.jpg')
        ],
        title1: 'EGC Exchange',
        title2: 'A Global Cryptocurrency Leader Since 2019',
        opened: 'Opened',
        more: 'More',
        trade: 'Trade',
        pair: 'Pair',
        price: 'Price',
        change: 'Change',
        high: 'High',
        low: 'Low',
        finished: 'Vol.',
        deal: 'Deal',
        service_title: 'Start Your Cryptocurrency Journey Today',
        service1: {
            title: 'Reliable System',
            des1: 'Guarantee the safe, stable and efficient operation of the transaction',
            des2: 'Elite team of top talent in the world'
        },
        service2: {
            title: 'Safety of fund',
            des1: 'Https data encryption, identity verification',
            des2: 'Financial level architecture, multiple security protection'
        },
        service3: {
            title: 'The best experience',
            des1: 'Rapid withdrawals, Multilingual',
            des2: 'High performance trading engine, fast trading experience'
        },
        service4: {
            title: 'Professional services',
            des1: 'Professional international service teams',
            des2: 'Respond to user problems in a timely manner'
        }
    },
    help: {
        title: 'Help Center',
        feedback: 'Feedback'
    },
    news: {
        title: 'News Center'
    },
    subscribe: {
        title: 'Abonnement Crypto',
        title1: 'Abonnez-vous à la crypto New Crypto',
        title2: 'Nous nous engageons à aider les projets de blockchain à lever des fonds,<br/>à trouver des supporters et à faire émerger de bonnes idées nouvelles et intéressantes.',
        new_currency: 'New currency',
        my_participation: 'My participation',
        project_details: 'Project Details',
        subscription_ratio: 'Subscription Ratio',
        current_price: 'Current Price',
        issue_quota: 'Issue Quota',
        participating: 'Participating',
        quantity: 'Quantity',
        enter_quantity: 'Enter Quantity',
        lock_period: 'Lock Period',
        months: 'Month(s)',
        thaw_interval: 'Thaw interval',
        approved: 'Approved',
        hours: 'Hour(s)',
        available: 'Available',
        ms: 'Mes Abonnement',
        mp: 'Mes Postes',
        purchase_limit: 'Purchase Limit',
        total: 'Total',
        fee: 'Fee',
        get_involved_now: 'Get Involved Now',
        end_date: 'End Date',
        project_des: 'Project Description',
        wtb: 'Waiting to Subscribe',
		Subscring: 'Open Subscribe',
		wtm: 'Waiting to Listing',
		Subscribe: 'Subscribe',
		on_market: 'Listed on Market',
		has_ended: 'Project has Ended',
		osfm: 'Open Subscribe for Me ?',
		Yes: "Yes",
		No: 'No',
		msr: 'My Subscribed Records',
		issue_date: 'Issue Date',
		Issue_Price: 'Issue Price',
		Now_Price: 'Now Price',
		Cost_Value: 'Cost Value',
		sd: 'Subscribe Date',
		daily: 'Daily',
		to: 'to',
		st: 'Subscribe Time',
		std: 'Listing Date',
		white_paper: 'White Paper',
		get_involved: 'Get Involved',
		eq: 'Enter Quantity',
		not_open_subscribe: 'Your Account not open for Subscribe, to Help',
		history_title: 'Subscribe Records',
		Fail_Rollback: 'Fail Rollback',
		Subscribing: 'Subscribing',
		oq: 'Order Quantity',
		sq: 'Quota Quantity',
		postitons_title: 'Crypto Postitons',
		cost: 'Cost',
		value: 'Value',
		atu: 'Apply to Unlock',
		locking_to: 'Locking to',
		finished: 'Finished',
		cost_or_price: 'Cost / Current Price',
		cost_or_value: 'Cost / Current Value',
		Profit: 'Profit',
		item_title: 'Project Details'
    },
    login: {
        title: 'Connexion',
        dont_have_an_account:'Vous n\'avez pas de compte ?',
        to_register: 'Inscription',
        email: 'Email',
        password: 'Mot de passe',
        captcha: 'Le code de vérification',
        click_me_show: 'Cliquez ici pour voir',
        sign_in_now: 'Connexion now',
        forget_password: 'Mot de passe oublié ?'
    },
    register: {
        title: 'Inscription',
        phone: 'Phone',
        re_password: 'Confirmez le mot de passe',
        invitation_code: 'Code d\'invitation',
        send_to_email: 'Send to Email',
        already_have_an_account: 'Vous avez déjà un compte ?',
        sign_in: 'Connexion'
    },
    forget: {
        title: 'Récupérer le mot de passe',
        retrieve_now: 'Récupérer'
    },
    account: {
        title: 'Compte',
        recharge: 'Dépôt',
        profile: 'Profil',
        my_assets: 'My Assets',
        withdraw: 'Retirer',
        transactions: 'Trade Orders',
        contract: 'Contract',
        options_orders: 'Options Orders',
        inviting_and_awards: 'Inviting & Awards',
        settings: 'Settings',
        list_of_assets: 'List of Assets',
        available: 'Available',
        frozen: 'Frozen',
        available_log: 'Available Log',
        event: 'Event',
        time: 'Time',
        email: 'Email',
        login_logs: 'Login Logs',
        change_password: 'Change Password',
        kyc_verify: 'KYC Verify',
        verify: 'Verify',
        verify_state: {
            unsubmitted: 'Unsubmitted',
            fail: 'Fail',
            checking: 'Checking',
            success: 'Success'
        }
    },
    wallet: {
        title: 'My Wallet',
        blockchain: 'Blockchain',
        hash: 'Hash',
        tx_hash: 'Tx Hash',
        sb: 'Select Blockchain',
        spot: 'Spot',
        total_value: 'Total Value',
        umb: 'USD(S)-M Margin Balance',
        positions: 'Positions',
        futures: 'Futures',
		tb: 'Total Balance',
		recharge: 'Dépôt',
		withdraw: 'Retirer',
		exchange: 'Swap',
		in_order: 'In Order',
		value: 'Value',
		pea: 'Please enter Amount',
		petp: 'Please enter Trade Password',
		log_type: {
			0: 'Dépôt',
			1: 'System add',
			2: 'System remove',
			6: 'Retirer',
			7: 'Retirer fail rollback',
			8: 'Swap out',
			9: 'Swap in',
			12: 'Frozen',
			13: 'Thaw',
			14: 'Transfer out',
			15: 'Transfer in',

			20: 'Coin subscribe',
			21: 'Coin unlock',
			22: 'Coin subscribe fail rollback',
			23: 'Coin unlock and sell',
			24: 'Coin unlock taxes',

			30: 'Order Buy Spot',
			31: 'Order Buy Spot',
			32: 'Cancel the Order for Spot Trade',
			33: 'Successful Trade Spot',

			50: 'Options Submit Order',
			51: 'Options Win',
			52: 'Options return Principal'
        }
	},
    recharge: {
		title: 'Dépôt',
		cc: 'Click Copy',
		uv: 'Upload Voucher',
		puv: 'Please Upload Voucher',
		nor: 'Not open Recharge',
		history: {
			title: 'Recharge Record',
			checking: 'Checking',
			success: 'Success',
			fail: 'Fail'
		}
	},
    withdraw: {
		title: 'Retirer',
        st: 'Submit Time',
		now: 'Not open Retirer',
        swa: 'Select Wallet Address',
        awa: 'Add Wallet Address',
        nwa: 'New Wallet Address',
        epwa: 'Enter Payee Wallet Address',
		history: {
			title: 'Retirer Record',
			checking: 'Checking',
			success: 'Success',
			fail: 'Fail',
			cancel: 'Cancel'
		}
	},
    exchange: {
		title: 'Coin Swap',
        from_amount: 'Export Amount',
        checkout_to: 'Checkout To',
		history: {
			title: 'Swap Record',
		}
	},
    transfer: {
		title: 'Transfer',
		history: {
			title: 'Transfer Record',
		}
	},
    share: {
		title: 'Share'
	},
    user: {
		email: 'Email',
		email_captcha: 'Email code d\'identification',
		captcha: 'Captcha',
		pwd: 'Password',
		confirm_pwd: 'Confirm Password',
		trade_pwd: 'Trade Password',
		confirm_trade_pwd: 'Confirm Trade Password',
		wa: 'Wallet Address',
		peea: 'Please enter Email Address',
		pewa: 'Please Enter Wallet Address',
		pec: 'Please enter Captcha',
		peec: 'Please enter Email Authentication Code',
		cfii: 'The Captcha only allows letters and numbers',
		ecfii: 'The Email authentication code only allows numbers',
		eafii: 'Email Address format is incorrect',
		pep: 'Please enter Password',
		pecp: 'Please enter Confirm Password',
		p_diff: 'The two passwords entered are inconsistent',
		pfii: 'The password only allows letters and numbers',
		uff: 'Upload File Fail'
	}
}