import Vue from 'vue'
import Vuex from 'vuex'
// import sha1 from 'sha1'
Vue.use(Vuex)
const state = {
    locale: localStorage.getItem('locale') || 'en',
    theme: localStorage.getItem('theme') || 'light',
    userToken: localStorage.getItem('userToken') || '',
    userSignKey: localStorage.getItem('userSignKey') || '',
    userId: localStorage.getItem('userId') || '',
    userEmail: localStorage.getItem('userEmail') || '',
    userPhone: localStorage.getItem('userPhone') || '',
    userInvitationCode: localStorage.getItem('userInvitationCode') || '',
    userVerified: localStorage.getItem('userVerified') || 0,
    marketCoin: localStorage.getItem('marketCoin') || 'USDT',
    newsID: localStorage.getItem('newsID') || 0,
    indexMarket: localStorage.getItem('indexMarket') || '',
    noticeList: localStorage.getItem('noticeList') || '',
    tradeActiveTabs: localStorage.getItem('tradeActiveTabs') || 'spot'
    
}
const mutations = {
    locale (state, locale) {
        if(['en','de','fr','zh-Hans','zh-Hant'].indexOf(locale)<0) return
        state.locale = locale
        localStorage.setItem('locale', locale)
        window.document.documentElement.lang = locale
    },
    theme (state, theme) {
        if(['light','dark'].indexOf(theme)<0) return
        state.theme = theme
        localStorage.setItem('theme', theme)
    },
    userToken (state, userToken) {
        state.userToken = userToken
        localStorage.setItem('userToken', userToken)
    },
    userEmail (state, userEmail) {
        state.userEmail = userEmail
        localStorage.setItem('userEmail', userEmail)
    },
    userPhone (state, userPhone) {
        state.userPhone = userPhone
        localStorage.setItem('userPhone', userPhone)
    },
    userId (state, userId) {
        state.userId = userId
        localStorage.setItem('userId', userId)
    },
    userInvitationCode (state, value) {
        state.userInvitationCode = value
        localStorage.setItem('userInvitationCode', value)
    },
    userVerified (state, userVerified) {
        state.userVerified = userVerified
        localStorage.setItem('userVerified', userVerified)
    },
    marketCoin (state, marketCoin) {
        state.marketCoin = marketCoin
        localStorage.setItem('marketCoin', marketCoin)
    },
    newsID (state, newsID) {
        state.newsID = newsID
        localStorage.setItem('newsID', newsID)
    },
    noticeList (state, value) {
        state.noticeList = JSON.stringify(value)
        localStorage.setItem('noticeList', state.noticeList)
    },
    indexMarket (state, value) {
        state.indexMarket = JSON.stringify(value)
        localStorage.setItem('indexMarket', state.indexMarket)
    },
    tradeActiveTabs (state, value) {
        state.tradeActiveTabs = value
        localStorage.setItem('tradeActiveTabs', value)
    },
    setUserInfo (state, info){
        if(info.token){
            state.userToken = info.token
            localStorage.setItem('userToken', info.token)
        }
        state.userEmail = info.email
        localStorage.setItem('userEmail', info.email)
        state.userInvitationCode = info.invitation_code
        localStorage.setItem('userInvitationCode', info.invitation_code)
        state.userVerified = info.verified
        localStorage.setItem('userVerified', info.verified)
    },
    set (state, set) {
        if(set.key == undefined || set.val == undefined || state[set.key] == undefined) return
        state[set.key] = set.val
        localStorage.setItem(set.key, set.val)
    }
}
const store = new Vuex.Store({
    state,
    mutations
})
export default store