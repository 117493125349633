// import IndexVue from './views/index.vue'
import Vue from 'vue'
import store from './store'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
    { 
        path: '/',
        component: () => import('@/views/index'),
        children: [
            {
                path: '',
                component: () => import('@/views/index/index')
            },
            {
                path: 'pages/*',
                redirect: to => {
                    console.log(to)
                    return to.path.replace('pages/','')
                }
            },
            {
                path: 'user/login',
                component: () => import('@/views/user/login')
            },
            {
                path: 'user/forget',
                component: () => import('@/views/user/forget')
            },
            {
                path: 'user/register/:invitation_code?',
                component: () => import('@/views/user/register')
            },
            {
                path: 'user/account',
                component: () => import('@/views/user/account/index'),
                meta: { auth: true },
                children: [
                    {
                        path: '',
                        redirect: 'profile'
                    },
                    {
                        path: 'profile',
                        component: () => import('@/views/user/account/profile')
                    },
                    {
                        path: 'share',
                        component: () => import('@/views/user/account/share')
                    },
                    {
                        path: 'subscribe',
                        component: () => import('@/views/user/account/subscribe')
                    },
                    {
                        path: 'transactions',
                        component: () => import('@/views/user/account/transactions')
                    },
                    {
                        path: 'options',
                        component: () => import('@/views/user/account/options')
                    },
                    {
                        path: 'inviting',
                        component: () => import('@/views/user/account/inviting')
                    },
                    {
                        path: 'verify',
                        component: () => import('@/views/user/settings/verify')
                    },
                    {
                        path: 'password',
                        component: () => import('@/views/user/settings/password')
                    },
                    {
                        path: 'pay-password',
                        component: () => import('@/views/user/settings/pay-password')
                    },
                    {
                        path: 'login-logs',
                        component: () => import('@/views/user/settings/login-logs')
                    }
                ]
            },
            {
                path: 'wallet',
                component: () => import('@/views/wallet/index'),
                meta: { auth: true },
                children: [
                    {
                        path: '',
                        redirect: 'spot'
                    },
                    {
                        path: 'spot',
                        component: () => import('@/views/wallet/spot')
                    },
                    {
                        path: 'futures',
                        component: () => import('@/views/wallet/futures')
                    },
                    {
                        path: 'recharge/:coin',
                        name: 'Recharge',
                        component: () => import('@/views/wallet/recharge')
                    },
                    {
                        path: 'withdraw/:coin',
                        name: 'Withdraw',
                        component: () => import('@/views/wallet/withdraw')
                    },
                    {
                        path: 'exchange',
                        name: 'exchange',
                        component: () => import('@/views/wallet/exchange')
                    },
                    {
                        path: 'transfer',
                        name: 'transfer',
                        component: () => import('@/views/wallet/transfer')
                    }
                ]
            },
            {
                path: 'market/:pair',
                component: () => import('@/views/market/index')
            },
            {
                path: 'usdsm/history',
                component: () => import('@/views/usdsm/history')
            },
            {
                path: 'subscribe',
                redirect: 'subscribe/index'
            },
            {
                path: 'subscribe/index',
                component: () => import('@/views/subscribe/index')
            },
            {
                path: 'subscribe/log',
                meta: { auth: true },
                component: () => import('@/views/subscribe/log')
            },
            {
                path: 'subscribe/positions',
                meta: { auth: true },
                component: () => import('@/views/subscribe/positions')
            },
            {
                path: 'subscribe/details/:coin',
                component: () => import('@/views/subscribe/details')
            },
            {
                path: 'help',
                redirect: 'help/6'
            },
            {
                path: 'help/:id',
                component: () => import('@/views/help/index')
            },
            {
                path: 'contact',
                meta: { auth: true },
                component: () => import('@/views/help/contact-us')
            },
            {
                path: 'notice',
                component: () => import('@/views/notice/index')
            },
            {
                path: 'notice/details/:id',
                component: () => import('@/views/notice/details')
            },
            {
                path: 'fees',
                component: () => import('@/views/fees')
            },
            {
                path: '*',
                component: () => import('@/views/404')
            }
        ]
    },
    
]
const router = new VueRouter({
    routes
})
router.onError(() => {
    console.error('路由错误')
})
router.beforeEach((to, from, next) => {
    // console.log(to)
    if(to.meta.auth && !store.state.userToken){
        let navCount = sessionStorage.getItem('navCount') || 0
        navCount++
        sessionStorage.setItem('navCount', navCount)
        router.push('/user/login')
    } else {
        next()
    }
})
Vue.prototype.$nav = {
    back: function() {
        let navCount = sessionStorage.getItem('navCount') || 0
        if(navCount > 0){
            // console.log('正常返回')
            router.back()
        }else{
            // console.log('返回重写')
            router.replace('/')
        }
    },
    replace: function(route) {
        router.replace(route)
    },
    push: function(route) {
        let navCount = sessionStorage.getItem('navCount') || 0
        navCount++
        sessionStorage.setItem('navCount', navCount)
        router.push(route)
    }
}
window.onpopstate = function() {
    let navCount = sessionStorage.getItem('navCount') || 0
    if(navCount > 0){
        navCount--
        sessionStorage.setItem('navCount', navCount)
    }
}
export default router