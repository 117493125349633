export default {
    submit: '提交',
    confirm: '確認',
    cancel: '取消',
    history: '歷史紀錄',
	amount: '金額',
    quantity: '數量',
	price: '價格',
	available: '可用',
	total: '合計',
	no_more: '沒有更多了',
    no_data: '沒有數據',
	optional: '可選',
	remark: '備註',
	tips: '溫馨提示',
	copied: '已複製',
	petf: '請輸入此字段',
	MAX: '最大值',
    open_orders: '委託訂單',
    order_no: '訂單號',
    date_time: '日期時間',
    status: '狀態',
    action: '操作',
    details: '詳情',
    back: '返回',
    refresh: '刷新',
    login_first: '去登錄賬號',
    coin: '幣種',
    profit: '收益',
    send: '發送',
    sending: '正在發送',
    name: '名稱',
    all: '全部',
    long: '買升',
    short: '賣跌',
    close: '關閉',
    navbar: {
        home: '首頁',
        subscribe: '新幣申購',
        fund: '區塊基金',
        trade: '交易中心',
        getapp: '下載App',
        help: '幫助中心',
        contact: '聯繫我們',
        language: '語言',
        account: '賬號',
        sign_in: '登錄',
        my_assets: '我的資產',
        profile: '個人資料',
        wallet: '錢包',
        user_center: '用戶中心',
        settings: '設置',
        logout: '退出登錄'
    },
    footer: {
        about_us: '關於我們',
        help_center: '幫助中心',
        fees: '手續費',
        user_agreement: '服務條款',
        contact_us: '聯繫我們',
        copyright: '所有版權'
    },
    base: {
        back: '返回',
        buy: '買入',
        sell: '賣出',
        no_more: '空列表',
        loading: '正在加載',
        sending: '正在發送',
        pending: '處理中',
        success: '成功',
        fail: '失敗',
        warning: '提示',
        danger: '警告',
        error: '錯誤',
        email: '郵箱',
        password: '密碼',
        old_pw: '舊密碼',
        new_pw: '新密碼',
        re_pw: '重輸密碼',
        pay_password: '交易密碼',
        submit: '提交',
        update: '更新',
        new: '新增',
        remark: '備註',
        voucher: '憑據',
        amount: '金額',
        enter_usdt_quantily: '請輸入USDT數量',
        enter_trade_password: '請輸入交易密碼',
        login_first: '請先登錄',
        state: '狀態',
        profit: '收益',
        coin: '幣',
        coin_profit: '幣收益',
        refresh: '刷新',
        total: '合計',
        deal: '操作'
    },
    home: {
        banner: [
            require('@/assets/index/banner1_zh-cn.png'),
            require('@/assets/index/banner2_zh-cn.png'),
            require('@/assets/index/top-bg.jpg')
        ],
        // title1: '大金加密貨幣交易所',
        title2: '一個書貨幣交易平臺, 起始於2019年',
        opened: '已開放',
        more: '更多',
        trade: '交易',
        pair: '交易對',
        price: '最新價格',
        change: '波動',
        high: '最高',
        low: '最低',
        finished: '成交量',
        deal: '操作',
        service_title: '立即開始您的加密貨幣之旅',
        service1: {
            title: '系統可靠',
            des1: '保證交易的安全，穩定，高效',
            des2: '世界頂尖人才的精英團隊'
        },
        service2: {
            title: '資金安全',
            des1: 'Https數據加密，身份驗證',
            des2: '冷錢包存儲，財務級別架構，多重安全保護'
        },
        service3: {
            title: '最好體驗',
            des1: '快速提款，多語言',
            des2: '高性能交易引擎，快速交易經驗'
        },
        service4: {
            title: '專業服務',
            des1: '專業的國際服務團隊',
            des2: '及時響應用戶問題'
        }
    },
    help: {
        title: '幫助中心',
        feedback: '問題反饋'
    },
    news: {
        title: '新聞和貼文'
    },
    subscribe: {
        title: '申購數字貨幣',
        title1: '發行和申購數字貨幣',
        title2: '我們致力於幫助區塊鏈項目籌集資金，尋找支持者，並提出新穎有趣的好主意',
        new_currency: '新幣',
        my_participation: '我的訂閱',
        project_details: '項目詳情',
        subscription_ratio: '認購比例',
        issue_date: '發行日期',
        issue_quota: '發行配額',
        current_price: '當前價格',
        participating: '已參與',
        lock_period: '鎖倉週期',
        months: '個月',
        thaw_interval: '解凍間隔',
        hours: '小時',
        available: '可用',
        purchase_limit: '參與限制',
        total: '合計',
        fee: '手續費',
        get_involved_now: '立即參與',
        end_date: '結束日期',
        project_des: '項目簡介',
        white_paper: '白皮書',
        get_involved: '我要參與',
		eq: '輸入數量',
		not_open_subscribe: '您的賬號不開放申購權限, 請聯係客服',
		history_title: '繩鈎歷史記錄',
		Fail_Rollback: '失敗回退',
		Subscribing: '申購處理中',
		oq: '下單數量',
		sq: '成功數量',
		postitons_title: '數字貨幣持倉',
		cost: '成本',
		value: '價值',
		atu: '申請解鎖',
		locking_to: '鎖倉至',
		finished: '已完結',
		cost_or_price: '成本/當前價格',
		cost_or_value: '成本/當前價值',
		Profit: '收益',
		item_title: '項目詳情',
        curreny_price: '當前價格',
        sd: '申購日期',
		daily: '每日',
		to: '至',
		st: '申購時間',
		std: '上市日期',
        wtb: '等待開始申購',
		Subscring: '正在申購',
		wtm: '等待上市',
		Subscribe: '申購',
		on_market: '已上市',
		has_ended: '已結束',
        ms: '我正在申購的',
        mp: '我的持倉',
    },
    market: {
        title: '市場',
        symbol: '交易名稱',
        order_book: '訂單簿',
        latest_price: '最新價格',
        position: '檔位',
        h24_vol: '24小時成交量',
        h24_change: '24小時波動',
        h24_high: '24小時最高',
        h24_low: '24小時最低',
        day1: '1日',
        min1: '1分',
        min5: '5分',
        min15: '15分',
        min30: '30分',
        hour1: '1小時',
    },
    spot:{
        title: '現貨',
        buy: '買入',
        sell: '賣出',
        type: '類型',
        cancel: '撤單'
    },
    options: {
		title: '秒合約',
		so: '自主下單',
		aio: 'AI下單',
		long: '看漲',
		short: '看跌',
		ca: '合約金額',
		details: '詳情',
		no: '新訂單',
		all: '全部',
		op: '開單價格',
		all_o: '全部訂單',
		ts: '去登陸',
		air: 'AI-Recommend',
		dt: '截至時間',
		profit: '收益',
        eia: '請輸入投資金額',
        confirm_order: '確認訂單',
        order_again: '重新下單',
        expected_profits: '預計盈利',
		min_invest_amount: '最低投資金額',
        latest_orders: '最新訂單',
        open_now: '立即開單',
        ocp: '開單/結單價格',
        oct: '開單/結單時機',
        ht: '秒合約歷史紀錄'
	},
	usdsm: {
		title: 'U本位合約',
		avail_margin: '可用保證金',
		symbol: '合約',
		perp: '永續',
		margin_mode: '保證金模式',
		cross: '全倉',
		cross_des: '保證金資產相同的全倉部位共享該資產的全倉保證金。在強平事件中，交易者可能會損失全部該保證金和該保證金資產下的所有全倉位。',
		isolated: '逐倉',
		isolated_des: '一定數量保證金被分配到部位。若倉位保證金虧損到低於維持保證金的水平，部位將強平。在逐倉模式下，您可以為這個部位添加和減少保證金。',
		margin_tips: '* 調整保證金模式僅對目前合約生效。',
		adjust_leverage: '調整槓桿',
		leverage_tips1: '* 請注意，槓桿變更也適用於未平倉部位和未平倉訂單。',
		leverage_tips2: '*選擇超過［10x］槓桿交易會增加強行平倉風險，請注意相關風險，更多資訊請參考這裡。',
		leverage_tips3: '* 始終管理您的風險等級。請參閱我們的說明文章以獲取更多資訊。',
		open: '開倉',
		close: '平倉',
		short: '空',
		long: '多',
		open_short: '開空',
		open_long: '開多',
		close_short: '平空',
		close_long: '平多',
		margin: '保證金',
		max_open: '最大開倉',
		max_close: '最大平倉',
		mark_price: '標記價格',
		market_price: '市場價格',
		limit_price: '限制價格',
		limit: '限價',
		entry_price: '開倉價格',
		position_size: '持倉數量',
		estimated_pnl:'預計盈虧',
		pnl: '浮動盈虧',
		roi: '投資回報率',
		margin_ratio: '保證金使用率',
		liq_price: '強平價格',
		size: '數量',
		filled: '已成交',
		order_history: '委託歷史紀錄',
		position_history: '持倉歷史紀錄',
		cancelled: '已撤單',
		pending: '等待成交',
		closing_pnl: '平倉盈虧',
		unrealized_pnl: '未平倉盈虧',
		partially_closed: '部分平倉',
		closed_vol: '已平倉數量',
		avg_close_price: '平倉均價',
		max_size: '最大持倉數量',
		closed: '已平倉',
		opened_time: '開倉時間',
		closed_time: '全部平倉時間',
        ht: 'U本位合約歷史紀錄'
	},
    login: {
        title: '會員登錄',
        dont_have_an_account:'您還沒有會員賬號 ?',
        to_register: '去註冊',
        email: '郵箱地址',
        password: '登錄密碼',
        captcha: '圖形驗證碼',
        click_me_show: '點擊顯示',
        sign_in_now: '立即登錄',
        forget_password: '忘記密碼 ?'
    },
    register: {
        title: '會員註冊',
        phone: '電話號碼',
        re_password: '重複密碼',
        invitation_code: '邀請碼',
        email_captcha: '郵箱驗證碼',
        send_to_email: '發送到郵箱',
        already_have_an_account: '您已有一個會員賬號 ?',
        sign_in: '登錄'
    },
    forget: {
        title: '重設密碼',
        retrieve_now: '立即重設'
    },
    account: {
        title: '賬戶',
        recharge: '充值',
        profile: '個人資料',
        invite_friends: '邀请好友',
        my_assets: '我的資產',
        withdraw: '提現',
        transactions: '現貨訂單',
        contract: '合約交易',
        inviting_and_awards: '邀請和返傭',
        options_orders: '秒合約交易記錄',
        settings: '設置',
        list_of_assets: '資產列表',
        available: '可用',
        frozen: '凍結',
        available_log: '可用記錄',
        event: '事件',
        time: '時間',
        login_logs: '登錄日志',
        change_password: '修改密碼',
        kyc_verify: 'KYC 認證',
        email: '郵箱地址',
        verify: '認證',
        verify_state: {
            unsubmitted: '未提交',
            fail: '失敗',
            checking: '待審核',
            success: '完成'
        }
    },
    wallet: {
        title: '我的錢包',
        blockchain: '區塊鏈',
        hash: '哈希',
        tx_hash: '交易哈希',
        sb: '選擇區塊鏈',
        sc: '選擇幣種',
        spot: '現貨',
        total_value: '縂價值',
        umb: 'U本位合約保證金餘額',
        positions: '持倉',
        futures: 'U本位合约',
		tb: '總資產摺合',
		recharge: '充幣',
		withdraw: '提幣',
		exchange: '兌換',
		in_order: '凍結',
		value: '摺合',
		pea: '請輸入金額',
		petp: '請輸入資金密碼',
		log_type: {
			0: '充幣',
			1: '系統添加',
			2: '系統移除',
			6: '提幣',
			7: '提幣失敗回退',
			8: '兌換出',
			9: '兌換入',
			12: '凍結',
			13: '解凍',
			14: '劃轉出',
			15: '劃轉入',

			20: '新幣申購',
			21: '新幣解鎖',
			22: '新幣申購失敗回退',
			23: '新幣解鎖并賣出',
			24: '新幣解鎖税费',

			30: '委託現貨買入',
			31: '委託現貨賣出',
			32: '現貨委托取消',
			33: '現貨交易成功',

			50: '秒合約提交訂單',
			51: '秒合約盈利',
			52: '秒合約本金'
        }
	},
    futures: {
        avail_margin: '可用保证金',
    },
    recharge: {
		title: '充幣',
		cc: '點擊複製',
		uv: '上傳憑據',
		puv: '請上傳憑據',
		nor: '不開放充值',
		history: {
			title: '充幣記錄',
			checking: '待審核',
			success: '成功到賬',
			fail: '失敗'
		}
	},
    withdraw: {
		title: '提幣',
        st: '提交時間',
		now: '不開放提幣',
        swa: '選擇錢包地址',
        awa: '添加錢包地址',
        nwa: '新的錢包地址',
        epwa: '輸入收款人錢包地址',
		history: {
			title: '提幣記錄',
			checking: '待審核',
			success: '成功',
			fail: '失敗',
			cancel: '取消'
		}
	},
    exchange: {
		title: '兌換',
        from_amount: 'Export Amount',
        checkout_to: 'Checkout To',
		history: {
			title: '兌換歷史記錄',
		}
	},
    transfer: {
		title: '劃轉',
		history: {
			title: '劃轉歷史記錄',
		}
	},
    share: {
		title: '分享好友',
		cic: '複製邀請碼',
		t: '區塊鏈基金推廣分享獎勵機制',
		t1: '直接推廣代理',
		t1_1: '1. 直接推廣2個人以下獲得交易流水2%USDT獎勵',
		t1_2: '2. 直接推廣3個人以上（包含3個人）獲得交易流水4%USDT獎勵',
		t1_3: '3. 直接推廣5個人以上（包含5個人）獲得交易流水6%USDT獎勵',
		t1_4: '4. 直接推廣10個人以上（包含10個人）獲得交易流水8%USDT獎勵',
		t2: '二級代理',
		t2_1: '1. 二級代理人達到4人以下，獲得交易流水1%USDT獎勵',
		t2_2: '2. 二級代理達到4人（包含4個人），獲得交易流水2%USDT獎勵',
		t2_3: '3. 二級代理達到6人（包含6個人），獲得交易流水4%USDT獎勵',
		t3: '三級代理',
		t3_1: '1. 三級代理達到1人，獲得交易流水3%USDT獎勵',
		t3_2: '2. 三級代理達到4人（包含4個人），獲得交易流水6%USDT獎勵',
		t3_3: '3. 三級代理達到6人（包含6個人），獲得交易流水8%USDT獎勵',
		team: {
			title: '邀請喝返傭',
			ct1: '合計邀請人數',
			ct2: '今日邀請人數',
			ct3: '今日收益',
			ct4: '合計收益',
			ct5: '合計餘額',
			level_1: '一級', 
			level_2: '二級', 
			level_3: '三級',
		}
	},
    user: {
		email: '郵箱地址',
		email_captcha: '郵箱驗證碼',
		captcha: '圖片驗證碼',
		pwd: '密碼',
        pwd_format_message: '6至32個字符，可包含字母、數字和常用標點符號 ',
		confirm_pwd: '確認密碼',
		trade_pwd: '資金密碼',
		confirm_trade_pwd: '確認資金密碼',
		wa: '錢包地址',
		peea: '請輸入郵箱地址',
		pewa: '請輸入錢包地址',
		pec: '請輸入圖片驗證碼',
		peec: '請輸入郵箱驗證碼',
		cfii: '圖形驗證碼格式為字母和數字組成',
		ecfii: '郵箱驗證碼為數字組成',
		eafii: '郵箱地址格式錯誤',
		pep: '請輸入密碼',
		pecp: '請確認密碼',
		p_diff: '兩次輸入的密碼不一致',
		pfii: '密碼為字母和數字和符號組成',
		uff: '上傳文件失敗'
	}
}