export default {
    submit: 'Submit',
    confirm: 'Confirm',
    cancel: 'Cancel',
    history: 'History',
	amount: 'Amount',
    quantity: 'Quantity',
	price: 'Price',
	available: 'Available',
	total: 'Total',
	total_balance: 'Total Balance',
	no_more: 'No More',
	no_data: 'No Data',
	optional: 'Optional',
    send: 'Send',
    sending: 'Sending',
    email: 'Email',
    eac: 'Email Authentication Code',
	remark: 'Remark',
	tips: 'Tips',
	copied: 'Copied',
	petf: 'Please enter this field',
	MAX: 'Max',
    open_orders: 'Open Orders',
    order_no: 'Order No.',
    date_time: 'Date Time',
    status: 'Status',
    action: 'Action',
    details: 'Details',
    back: 'Back',
    refresh: 'Refresh',
    login_first: 'Sign In First',
    coin: 'Coin',
    profit: 'Profit',
    name: 'Name',
    all: 'All',
    long: 'Long',
    short: 'Short',
    close: 'Close',
    navbar: {
        home: 'Home',
        subscribe: 'Subscribe',
        fund: 'Funds',
        trade: 'Trade',
        getapp: 'App',
        help: 'Help',
        contact: 'Contact Us',
        language: 'Language',
        sign_in: 'Sign In',
        account: 'Account',
        my_assets: 'My Wallet',
        wallet: 'Wallet',
        profile: 'Profile',
        user_center: 'User Center',
        settings: 'Settings',
        logout: 'Logout'
    },
    footer: {
        about_us: 'About Us',
        help_center: 'Help Center',
        fees: 'Fees',
        user_agreement: 'User Agreement',
        contact_us: 'Contact Us',
        copyright: 'Copyright'
    },
    base: {
        back: 'Back',
        buy: 'Buy',
        sell: 'Sell',
        no_more: 'No more',
        loading: 'Loading',
        sending: 'Sending',
        pending: 'Pending',
        success: 'Success',
        fail: 'Fail',
        warning: 'Warning',
        danger: 'Danger',
        error: 'Error',
        email: 'Email',
        password: 'Password',
        old_pw: 'Old-Passwd',
        new_pw: 'New-Passwd',
        re_pw: 'Re-Passwd',
        pay_password: 'Trade Password',
        submit: 'Submit',
        update: 'Update',
        new: 'New',
        remark: 'Remark',
        voucher: 'Voucher',
        amount: 'Amount',
        enter_usdt_quantily: 'Enter USDT quantily',
        enter_trade_password: 'Enter trade password',
        login_first: 'Login first',
        state: 'State',
        profit: 'Profit',
        coin_profit: 'Coin Profit',
        refresh: 'Refresh',
        total: 'Total',
        deal: 'Deal'
    },
    home: {
        banner: [
            require('@/assets/index/banner1_en-us.png'),
            require('@/assets/index/banner2_en-us.png'),
            require('@/assets/index/top-bg.jpg')
        ],
        title1: 'EGC Exchange',
        title2: 'A Global Cryptocurrency Leader Since 2020',
        opened: 'Opened',
        more: 'More',
        trade: 'Trade',
        pair: 'Pair',
        price: 'Price',
        change: 'Change',
        high: 'High',
        low: 'Low',
        finished: 'Vol.',
        deal: 'Deal',
        service_title: 'Start Your Cryptocurrency Journey Today',
        service1: {
            title: 'Reliable System',
            des1: 'Guarantee the safe, stable and efficient operation of the transaction',
            des2: 'Elite team of top talent in the world'
        },
        service2: {
            title: 'Safety of fund',
            des1: 'Https data encryption, identity verification',
            des2: 'Financial level architecture, multiple security protection'
        },
        service3: {
            title: 'The best experience',
            des1: 'Rapid withdrawals, Multilingual',
            des2: 'High performance trading engine, fast trading experience'
        },
        service4: {
            title: 'Professional services',
            des1: 'Professional international service teams',
            des2: 'Respond to user problems in a timely manner'
        }
    },
    help: {
        title: 'Help Center',
        feedback: 'Feedback',
        contact_us: 'Contact Us',
        sub_title: 'If you encounter any problems during use,<br/>please contact us using the following<br/>contact information!',
        contact_details: 'Contact Details'
    },
    news: {
        title: 'News Center'
    },
    subscribe: {
        title: 'Subscribe Crypto',
        title1: 'Subscribing New Crypto',
        title2: 'We are committed to helping blockchain projects raise funds,<br/>find supporters, and make fresh and interesting good ideas come',
        new_currency: 'New currency',
        my_participation: 'My participation',
        project_details: 'Project Details',
        subscription_ratio: 'Subscription Ratio',
        current_price: 'Current Price',
        issue_quota: 'Issue Quota',
        participating: 'Participating',
        quantity: 'Quantity',
        enter_quantity: 'Enter Quantity',
        lock_period: 'Lock Period',
        months: 'Month(s)',
        thaw_interval: 'Thaw interval',
        approved: 'Approved',
        hours: 'Hour(s)',
        available: 'Available',
        ms: 'My Subscribing',
        mp: 'My Positions',
        purchase_limit: 'Purchase Limit',
        total: 'Total',
        fee: 'Fee',
        get_involved_now: 'Get Involved Now',
        end_date: 'End Date',
        project_des: 'Project Description',
        wtb: 'Waiting to Subscribe',
		Subscring: 'Open Subscribe',
		wtm: 'Waiting to Listing',
		Subscribe: 'Subscribe',
		on_market: 'Listed on Market',
		has_ended: 'Project has Ended',
		osfm: 'Open Subscribe for Me ?',
		Yes: "Yes",
		No: 'No',
		msr: 'My Subscribed Records',
		issue_date: 'Issue Date',
		Issue_Price: 'Issue Price',
		Now_Price: 'Now Price',
		Cost_Value: 'Cost Value',
		sd: 'Subscribe Date',
		daily: 'Daily',
		to: 'to',
		st: 'Subscribe Time',
		std: 'Listing Date',
		white_paper: 'White Paper',
		get_involved: 'Get Involved',
		eq: 'Enter Quantity',
		not_open_subscribe: 'Your Account not open for Subscribe, to Help',
		history_title: 'Subscribe Records',
		Fail_Rollback: 'Fail Rollback',
		Subscribing: 'Subscribing',
		oq: 'Order Quantity',
		sq: 'Quota Quantity',
		postitons_title: 'Crypto Postitons',
		cost: 'Cost',
		value: 'Value',
		atu: 'Apply to Unlock',
		locking_to: 'Locking to',
		finished: 'Finished',
		cost_or_price: 'Cost / Current Price',
		cost_or_value: 'Cost / Current Value',
		Profit: 'Profit',
		item_title: 'Project Details'
    },
    market: {
        title: 'Market',
        symbol: 'Symbol',
        order_book: 'Order Book',
        latest_price: 'Latest Price',
        position: 'Position',
        h24_vol: '24H Vol',
        h24_change: '24H Change',
        h24_high: '24H High',
        h24_low: '24H Low',
        day1: '1Day',
        min1: '1Min',
        min5: '5Min',
        min15: '15Min',
        min30: '30Min',
        hour1: '1Hour',
    },
    spot:{
        title: 'Spot',
        buy: 'Buy',
        sell: 'Sell',
        type: 'Type',
        cancel: 'Cancel'
    },
    options: {
		title: 'Options',
		so: 'Self-order',
		aio: 'AI-order',
		long: 'Buy Long',
		short: 'Short Selling',
		ca: 'Contract Amount',
		details: 'Details',
		no: 'New Orders',
		all: 'All',
		op: 'Open Price',
		all_o: 'All Orders',
		nsc: 'Not sign any in effect contract',
		ts: 'To Sign',
		air: 'AI-Recommend',
		dt: 'Due Time',
		profit: 'Profit',
        eia: 'Enter Investment Amount',
        confirm_order: 'Confirm Order',
        order_again: 'Order Again',
        expected_profits: 'Expected Profits',
		iwco: 'It was contract order',
		aio_is_running: 'AI-order is running',
		start_aio: 'Start AI-order',
		bc: 'by contract',
		min_invest_amount: 'Min Invest Amount',
        latest_orders: 'Lstest Options',
        open_now: 'Open Now',
        ocp: 'Opened / Closed Price',
        oct: 'Opened / Closed Time',
		ht: 'Options History'
	},
	usdsm: {
		title: 'USD(S)-M',
		avail_margin: 'Avail Margin',
		symbol: 'Symbol',
		perp: 'Perp',
		margin_mode: 'Margin Mode',
		cross: 'Cross',
		cross_des: 'All cross positions under the same margin asset share the same asset cross margin balance. In the event of liquidation, your assets full margin balance along with any remaining open positions under the asset may be forfeited.',
		isolated: 'Isolated',
		isolated_des: 'The Margin assigned to a position is restricted to a certain amount. If the Margin falls below the Maintenance Margin level, the position is liquidated. However, you can add and remove Margin at will under this mode.',
		margin_tips: '* Switching of margin modes only applies to the selected contract.',
		adjust_leverage: 'Adjust Leverage',
		leverage_tips1: '* Please note that leverage changing will also apply for open positions anc open orders.',
		leverage_tips2: '* Selecting higher leverage such as[10x] increases your liquidation risk.',
		leverage_tips3: '* Always manage your risk levels. See our help article for more information.',
		open: 'Open',
		close: 'Close',
		short: 'Short',
		long: 'Long',
		open_short: 'Open Short',
		open_long: 'Open Long',
		close_short: 'Clost Short',
		close_long: 'Close Long',
		margin: 'Margin',
		max_open: 'Max Open',
		max_close: 'Max Close',
		mark_price: 'Mark Price',
		market_price: 'Market Price',
		limit_price: 'Limit Price',
		limit: 'Limit',
		entry_price: 'Entry Price',
		position_size: 'Position Size',
		estimated_pnl:'Estimated PNL',
		pnl: 'PNL',
		roi: 'ROI',
		margin_ratio: 'Margin Ratio',
		liq_price: 'Liq.Price',
		size: 'Size',
		filled: 'Filled',
		order_history: 'Order History',
		position_history: 'Position History',
		cancelled: 'Cancelled',
		pending: 'Pending',
		closing_pnl: 'Closeing PNL',
		unrealized_pnl: 'Unrealized PNL',
		partially_closed: 'Partially Closed',
		closed_vol: 'Closed Vol',
		avg_close_price: 'Avg Close Price',
		max_size: 'Max Open Size',
		closed: 'Closed',
		opened_time: 'Opened',
		closed_time: 'Closed',
        ht: 'USD(S)-Margin History'
	},
    login: {
        title: 'Sign In',
        dont_have_an_account:'Don\'t have an account ?',
        to_register: 'To Sign Up',
        email: 'Email',
        password: 'Password',
        captcha: 'Captcha',
        click_me_show: 'Click Me Show',
        sign_in_now: 'Sign in now',
        forget_password: 'Forgot Password ?'
    },
    register: {
        title: 'Sign Up',
        phone: 'Phone',
        re_password: 'Confirm Password',
        invitation_code: 'Invitation Code',
        send_to_email: 'Send to Email',
        already_have_an_account: 'Already have an account ?',
        sign_in: 'Sign in'
    },
    forget: {
        title: 'Retrieve the Password',
        retrieve_now: 'Retrieve now'
    },
    account: {
        title: 'Account',
        recharge: 'Deposit',
        profile: 'Profile',
        invite_friends: 'Invite friends',
        my_assets: 'My Assets',
        withdraw: 'Withdraw',
        transactions: 'Spot Orders',
        contract: 'Contract',
        options_orders: 'Options Orders',
        inviting_and_awards: 'Inviting & Awards',
        settings: 'Settings',
        list_of_assets: 'List of Assets',
        available: 'Available',
        frozen: 'Frozen',
        available_log: 'Available Log',
        event: 'Event',
        time: 'Time',
        email: 'Email',
        login_logs: 'Login Logs',
        change_password: 'Change Password',
        kyc_verify: 'KYC Verify',
        verify: 'Verify',
        verify_state: {
            unsubmitted: 'Unsubmitted',
            fail: 'Fail',
            checking: 'Checking',
            success: 'Success'
        }
    },
    wallet: {
        title: 'My Wallet',
        blockchain: 'Blockchain',
        hash: 'Hash',
        tx_hash: 'Tx Hash',
        sb: 'Select Blockchain',
        sc: 'Select Coin',
        spot: 'Spot',
        total_value: 'Total Value',
        umb: 'USD(S)-Margin Balance',
        positions: 'Positions',
        futures: 'USD(S)-M',
		tb: 'Total Balance',
		recharge: 'Deposit',
		withdraw: 'Withdraw',
		exchange: 'Swap',
		in_order: 'In Order',
		value: 'Value',
		pea: 'Please enter Amount',
		petp: 'Please enter Trade Password',
		log_type: {
			0: 'Deposit',
			1: 'System add',
			2: 'System remove',
			6: 'Withdraw',
			7: 'Withdraw fail rollback',
			8: 'Swap out',
			9: 'Swap in',
			12: 'Frozen',
			13: 'Thaw',
			14: 'Transfer out',
			15: 'Transfer in',

			20: 'Coin subscribe',
			21: 'Coin unlock',
			22: 'Coin subscribe fail rollback',
			23: 'Coin unlock and sell',
			24: 'Coin unlock taxes',

			30: 'Order Buy Spot',
			31: 'Order Sell Spot',
			32: 'Cancel the Order for Spot Trade',
			33: 'Successful Trade Spot',

			50: 'Options Submit Order',
			51: 'Options Win',
			52: 'Options return Principal'
        }
	},
    futures: {
        avail_margin: 'Avail Margin',
    },
    recharge: {
		title: 'Deposit',
		cc: 'Click Copy',
		uv: 'Upload Voucher',
		puv: 'Please Upload Voucher',
		nor: 'Not open Recharge',
		history: {
			title: 'Recharge Record',
			checking: 'Checking',
			success: 'Success',
			fail: 'Fail'
		}
	},
    withdraw: {
		title: 'Withdraw',
        st: 'Submit Time',
		now: 'Not open Withdraw',
        swa: 'Select Wallet Address',
        awa: 'Add Wallet Address',
        nwa: 'New Wallet Address',
        epwa: 'Enter Payee Wallet Address',
		history: {
			title: 'Withdraw Record',
			checking: 'Checking',
			success: 'Success',
			fail: 'Fail',
			cancel: 'Cancel'
		}
	},
    exchange: {
		title: 'Coin Swap',
        from_amount: 'Export Amount',
        checkout_to: 'Checkout To',
		history: {
			title: 'Swap Record',
		}
	},
    transfer: {
		title: 'Transfer',
		history: {
			title: 'Transfer Record',
		}
	},
    share: {
		title: 'Share',
		cic: 'Copy Invitation Code',
		t: 'Incentive mechanism of Blockchain fund promotion and sharing',
		t1: 'Direct promotion agent',
		t1_1: '1. 2% USDT of transaction flow will be rewarded when the number of directly promotion agents is less than 2 people',
		t1_2: '2. 4% USDT of transaction flow will be rewarded when the number of directly promotion agents is more than 3 people (including 3 people)',
		t1_3: '3. 6% USDT of transaction flow will be rewarded when the number of directly promotion agents is more than 5 people (including 5 people)',
		t1_4: '4. 8% USDT of transaction flow will be rewarded when the number of directly promotion agents is more than 10 people (including 10 people)',
		t2: 'Secondary agent',
		t2_1: '1. 1% USDT of transaction flow will be rewarded when the number of secondary agents is less than 4 people',
		t2_2: '2. 2% USDT of transaction flow will be rewarded when the number of secondary agents is more than 4 people（including 4）',
		t2_3: '3. 4% USDT of transaction flow will be rewarded when the number of secondary agents is more than 6 people（including 6）',
		t3: 'Third-level agent',
		t3_1: '1. 3% USDT of transaction flow will be rewarded when the number of third-level agent is one people',
		t3_2: '2. 6% USDT of transaction flow will be rewarded when the number of third-level agents is more than 4 people（including 4）',
		t3_3: '3. 8% USDT of transaction flow will be rewarded when the number of third-level agents is more than 6 people（including 6）',
		team: {
			title: 'Invited & Awards',
			ct1: 'Total invited user(s)',
			ct2: 'Today new invited user(s)',
			ct3: 'Today income',
			ct4: 'Total income',
			ct5: 'Total balance',
			level_1: 'Level 1', 
			level_2: 'Level 2', 
			level_3: 'Level 3',
		}
	},
    user: {
		email: 'Email',
		email_captcha: 'Email Authentication Code',
		captcha: 'Captcha',
		pwd: 'Password',
        pwd_format_message: '6 to 32 characters, including letters, numbers, and common punctuation marks.',
		confirm_pwd: 'Confirm Password',
		trade_pwd: 'Trade Password',
		confirm_trade_pwd: 'Confirm Trade Password',
		wa: 'Wallet Address',
		peea: 'Please enter Email Address',
		pewa: 'Please Enter Wallet Address',
		pec: 'Please enter Captcha',
		peec: 'Please enter Email Authentication Code',
		cfii: 'The Captcha only allows letters and numbers',
		ecfii: 'The Email authentication code only allows numbers',
		eafii: 'Email Address format is incorrect',
		pep: 'Please enter Password',
		pecp: 'Please enter Confirm Password',
		p_diff: 'The two passwords entered are inconsistent',
		pfii: 'The password only allows letters and numbers',
		uff: 'Upload File Fail'
	}
}